import React from 'react'
import cwc from "../assets/cwc.png"
import evan from "../assets/evangelio.png"
import arlib from "../assets/Frame 5.png"
import skill from "../assets/skill.png"
import rk from "../assets/rookie.png"

import rookie from "../assets/Frame 7.png"
import holychic from "../assets/holychic.png"
import recipe from "../assets/Recipe App UI.png"
import flames from "../assets/flames.png"

const Works = () => {
  return (
    <div className='pb-24'>

<div className=" text-center   text-[#1d1d1d]">
         <h2 className="text-xl font-medium ">Yeah, I work hard 💼</h2>
       <p className=" text-sm px-1">Each project is unique. Here are some of my works.</p>
     </div>





     <div className="flex justify-center px-6">

     <div className=" pt-6 ">
          <h1 className=" text-center font-semibold text-base mb-2 ">
           #1 Logo Design 🧩
          </h1>


          <a href="#">

            <div  className=" hover:bg-[#ebebeb] p-2  mt-3 rounded-2xl">
            <div className="flex  ">
            <img
              className="w-24 h-24 "
              src={cwc}
              fill
              style={{ objectFit: "cover" }}
            />
            <p className="pl-5  pt-2">
              {" "}
              <a className="font-semibold text-xl " href="#">
                Crosswork Creation
              </a>
              <br />
              <p className=" font-medium   text-[#636e72]">
             Christian ministry for young people.
              </p>
           
            </p>
          </div>

            </div>

         


          </a>



          <a href="https://dribbble.com/shots/21885702-LOGO-DESIGN-SkillRANK?utm_source=Clipboard_Shot&utm_campaign=abishuablessmic&utm_content=LOGO%20DESIGN%20%7C%20SkillRANK&utm_medium=Social_Share&utm_source=Clipboard_Shot&utm_campaign=abishuablessmic&utm_content=LOGO%20DESIGN%20%7C%20SkillRANK&utm_medium=Social_Share">

<div  className=" hover:bg-[#ebebeb] p-2  mt-3 rounded-2xl">
<div className="flex  ">
<img
  className="w-24 h-24 "
  src={skill}
  fill
  style={{ objectFit: "cover" }}
/>
<p className="pl-5  pt-2">
  {" "}
  <a className="font-semibold text-xl " href="#">
    Skill Rank
  </a>
  <br />
  <p className=" font-medium   text-[#636e72]">
  Tech hiring platform. 
  </p>

</p>
</div>

</div>




</a>






<a href="#">

<div  className=" hover:bg-[#ebebeb] p-2  mt-3 rounded-2xl">
<div className="flex  ">
<img
  className="w-24 h-24 "
  src={rk}
  fill
  style={{ objectFit: "cover" }}
/>
<p className="pl-5  pt-2">
  {" "}
  <a className="font-semibold text-xl " href="#">
    Rookie
  </a>
  <br />
  <p className=" font-medium   text-[#636e72]">
 Job board platform for freshers .
  </p>

</p>
</div>

</div>




</a>




          <a href="#">

<div  className=" hover:bg-[#ebebeb] p-2  mt-3 rounded-2xl">
<div className="flex  ">
<img
  className="w-24 h-24 "
  src={evan}
  fill
  style={{ objectFit: "cover" }}
/>
<p className="pl-5  pt-2">
  {" "}
  <a className="font-semibold text-xl " href="#">
    Evangelio
  </a>
  <br />
  <p className=" font-medium   text-[#636e72]">
Social media marketing agency.
  </p>

</p>
</div>

</div>




</a>






       
         
        </div>







        

</div>






<div className="flex justify-center px-6  ">

<div className=" pt-6  ">
     <h1 className=" text-center font-semibold text-base mb-2 ">
      #2 UI/UX Design 🔮
     </h1>


     <a href="https://dribbble.com/shots/21885768-AR-Library-App-UI?utm_source=Clipboard_Shot&utm_campaign=abishuablessmic&utm_content=AR%20Library%20%20App%20UI&utm_medium=Social_Share&utm_source=Clipboard_Shot&utm_campaign=abishuablessmic&utm_content=AR%20Library%20%20App%20UI&utm_medium=Social_Share">

       <div  className=" bg-[#ebebeb] rounded-2xl">
       <div className="flex justify-center   p-2   mt-3  ">
       <img
         className="lg:w-72  w-200 h-100 rounded-xl "
         src={arlib}
         fill
         style={{ objectFit: "cover" }}
       />
       
     </div>

     <p className='pb-1 text-center font-semibold text-xl  '> AR Library App UI</p>

       </div>

    


     </a>



<div className='pt-4'>

<a href="https://dribbble.com/shots/22146949-Job-Board-Landing-Page-UI?new_shot_upload=true&utm_source=Clipboard_Shot&utm_campaign=abishuablessmic&utm_content=Job%20Board%20Landing%20Page%20UI&utm_medium=Social_Share&utm_source=Clipboard_Shot&utm_campaign=abishuabl&utm_source=Clipboard_Shot&utm_campaign=abishuablessmic&utm_content=Job%20Board%20Landing%20Page%20UI&utm_medium=Social_Share&utm_source=Clipboard_Shot&utm_campaign=abishuablessmic&utm_content=Job%20Board%20Landing%20Page%20UI&utm_medium=Social_Share" >




<div  className=" bg-[#ebebeb] rounded-2xl">
  <div className="flex justify-center   p-2   mt-3  ">
  <img
    className="lg:w-72  w-200 h-100 rounded-xl "
    src={rookie}
    fill
    style={{ objectFit: "cover" }}
  />
  
</div>

<p className='pb-1 text-center font-semibold text-xl  '> Job Board Landing Page </p>

  </div>


</a>

</div>




<div className='pt-4'>

<a href="https://dribbble.com/shots/21625056-Fashion-Landing-Page-UI?utm_source=Clipboard_Shot&utm_campaign=abishuablessmic&utm_content=Fashion%20Landing%20Page%20-UI&utm_medium=Social_Share&utm_source=Clipboard_Shot&utm_campaign=abishuablessmic&utm_content=Fashion%20Landing%20Page%20-UI&utm_medium=Social_Share" >




<div  className=" bg-[#ebebeb] rounded-2xl">
  <div className="flex justify-center   p-2   mt-3  ">
  <img
    className="lg:w-72  w-200 h-100 rounded-xl "
    src={holychic}
    fill
    style={{ objectFit: "cover" }}
  />
  
</div>

<p className='pb-1 text-center font-semibold text-xl  '> Fashion Landing Page </p>

  </div>


</a>

</div>




<div className='pt-4'>

<a href="https://dribbble.com/shots/21885605-Recipe-App-UI-Cooking-UI?utm_source=Clipboard_Shot&utm_campaign=abishuablessmic&utm_content=Recipe%20App%20UI%20%7C%20Cooking%20UI&utm_medium=Social_Share&utm_source=Clipboard_Shot&utm_campaign=abishuablessmic&utm_content=Recipe%20App%20UI%20%7C%20Cooking%20UI&utm_medium=Social_Share" >




<div  className=" bg-[#ebebeb] rounded-2xl">
  <div className="flex justify-center   p-2   mt-3  ">
  <img
    className="lg:w-72  w-200 h-100 rounded-xl "
    src={recipe}
    fill
    style={{ objectFit: "cover" }}
  />
  
</div>

<p className='pb-1 text-center font-semibold text-xl  '>Recipe App UI </p>

  </div>


</a>

</div>






<div className='pt-4'>

<a href="https://dribbble.com/shots/18522095-Flames-Calculator-1?utm_source=Clipboard_Shot&utm_campaign=abishuablessmic&utm_content=Flames%20Calculator%20%231&utm_medium=Social_Share&utm_source=Clipboard_Shot&utm_campaign=abishuablessmic&utm_content=Flames%20Calculator%20%231&utm_medium=Social_Share" >




<div  className=" bg-[#ebebeb] rounded-2xl">
  <div className="flex justify-center   p-2   mt-3  ">
  <img
    className="lg:w-72  w-200 h-100 rounded-xl "
    src={flames}
    fill
    style={{ objectFit: "cover" }}
  />
  
</div>

<p className='pb-1 text-center font-semibold text-xl  '>Flames Calculator App UI </p>

  </div>


</a>

</div>



  
    
   </div>







   

</div>

  

    </div>
  )
}

export default Works




// import React from "react";

// import { Link } from "react-router-dom";

// const Works = () => {
//   return (
//     <div className="lg:mx-56">
//       <div className=" text-center   text-[#1d1d1d]">
//         <h2 className="text-xl font-medium ">Yeah, I work hard 💼</h2>
//         <p className=" text-sm px-1">Each project is unique. Here are some of my works.</p>
//       </div>

//       {/* !!!!!  TABLE view !!!!! */}

//       <div className="p-4 ">
//         <div className="flex justify-between my-3">
//           <h2 className="font-semibold text-2xl  text-[#1d1d1d]">My Works</h2>
//           <p className="font-semibold text-lg ">04</p>
//         </div>

//         <hr />

//         {/* #my works */}

//         <div className="flex justify-between my-4 ">
//           <Link to="/works/cwc">
            
//             <h2 className="font-medium text-lg text-[#1d1d1d]">Crosswork Creation </h2>
//           </Link>

//           <p className="text-sm text-[#7A797D] ">Branding</p>
//         </div>

//         <hr />

//         <div className="flex justify-between my-4">
//           <Link to="/works/sakthicaad" >
           
//             <h2 className="font-medium text-lg  text-[#1d1d1d]">Sakthicaad</h2>
//           </Link>

//           <p className="text-sm text-[#7A797D]">Ui/Ux Design</p>
//         </div>

//         <hr />

//         <div className="flex justify-between my-4">
//           <a href="">
            
//             <h2 className="font-medium text-lg  text-[#1d1d1d]">Evangelio</h2>
//           </a>

//           <p className="text-sm text-[#7A797D]">Branding</p>
//         </div>

//         <hr />

//         <div  className="flex justify-between my-4">
//           <a href="" >
         
//             <h2 className="font-medium text-lg  text-[#1d1d1d]"> Foliohunt</h2>
//           </a>

//           <p className="text-sm text-[#7A797D] ">Branding</p>
//         </div>

//         <hr />
//       </div>

     
//     </div>
//   );
// }

// export default Works;








// {/* <div className={height > 20 ? "navbar-container nav-background" : "navbar-container "}>
// <Link to="/">
//   <img src={logo} className={closeClass ? `logo-animation logo-image` : "logo-image"} alt="logo of my name" />
// </Link>
// <div className="socialmedia-container pr-2 text-[#1d1d1d]" style={{ display: closeClass ? "flex" : "none" }}>
//   <a href="https://dribbble.com/abishuablessmic" target="_blank" className="socialmedia-text">
//     🏀 dribb
//   </a>
//   <a href="https://github.com/abishuablessmic" target="_blank" className="socialmedia-text">
//     👾 git
//   </a>
//   <a href="https://medium.com/@abishuablessmic" target="_blank" className="socialmedia-text">
//     📚 medi
//   </a>
//   <a href="https://instagram.com/abishuablessmic" target="_blank" className="socialmedia-text">
//     📸 insta
//   </a>
// </div> */}