import React from "react";
import avatar from "../assets/avatar.svg";

import hi from "../assets/abhi.png";

const Sayhi = () => {
  return (
    <div>
      {/* ***************  Container  ******************/}

      <div className=" text-center mt-3">
        {/* Available status */}

        <div className="flex justify-center ">
          <div className="mt-[1.2rem]">
            <div class="flex absolute ">
              <span class="animate-ping absolute h-[11px] w-[11px] -top-4 -left-4 rounded-full bg-green-500 opacity-75"></span>

              <span class="relative rounded-full h-[11px] w-[11px] -top-4 -left-4 bg-green-500"></span>
            </div>
          </div>

          <div
            className=" font-medium text-sm
     pl-1"
          >
            <span>Available for work.</span>
          </div>
        </div>

        {/* <div className="  flex mt-9 items-center " >
          <div className="  " >
            
            
                <div class="flex h-8 w-8 absolute ">
                  <span class="animate-ping absolute h-4 w-4 -top-4 -left-4 rounded-full bg-green-500 opacity-75"></span>

                  <span class="relative rounded-full h-4 w-4 -top-4 -left-4 bg-green-500"></span>
                </div>
             
            
            <div>
              <p className=" ">Available to work</p>
            </div>


          </div>
        </div> */}

        {/* Hero text */}

        <div className="mt-4">
          <h1 className="font-semibold text-4xl text-[#1d1d1d]">
            Let’s build 🏗️
            <br /> something great↓.
          </h1>
          <p className="font-medium">(would love to hear from you)</p>
        </div>

        {/* mail address */}

        <div className="mt-4">
          <p className="">email:</p>
          <button class="mt-2 h-11 px-6 text-sm border-2 font-medium text-[#1d1d1d] border-[#929292] rounded-md ">
            {" "}
            <a href="mailto: mailtoabishua@gmail.com">
              mailtoabishua@gmail.com
            </a>
          </button>
        </div>

        {/* Social Links */}

        <div className="mt-4 ">
          <p className="font-medium"> or follow me on</p>

          <div className=" mt-2 inline-grid  gap-5 grid-cols-6  ">
            {/* Instagram */}

            <a href="https://www.instagram.com/storiesofabi/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g id="Social/Instagram">
                  <path
                    id="Vector"
                    d="M17.0464 6.99945H17.0564M3.04639 12C3.04639 9.48639 3.04639 8.22958 3.39993 7.21924C4.03315 5.40959 5.45598 3.98676 7.26563 3.35354C8.27597 3 9.53278 3 12.0464 3C14.56 3 15.8168 3 16.8272 3.35354C18.6368 3.98676 20.0596 5.40959 20.6929 7.21924C21.0464 8.22958 21.0464 9.48639 21.0464 12C21.0464 14.5136 21.0464 15.7704 20.6929 16.7808C20.0596 18.5904 18.6368 20.0132 16.8272 20.6465C15.8168 21 14.56 21 12.0464 21C9.53278 21 8.27597 21 7.26563 20.6465C5.45598 20.0132 4.03315 18.5904 3.39993 16.7808C3.04639 15.7704 3.04639 14.5136 3.04639 12ZM15.8239 11.4398C15.9417 12.2344 15.806 13.0458 15.436 13.7588C15.0661 14.4718 14.4808 15.0499 13.7633 15.4111C13.0458 15.7722 12.2327 15.8979 11.4397 15.7703C10.6467 15.6427 9.91407 15.2683 9.3461 14.7003C8.77813 14.1323 8.40371 13.3997 8.27611 12.6067C8.1485 11.8137 8.2742 11.0006 8.63532 10.2831C8.99644 9.56564 9.57461 8.98031 10.2876 8.61036C11.0006 8.24042 11.812 8.1047 12.6065 8.22253C13.417 8.3427 14.1673 8.72036 14.7467 9.29971C15.326 9.87907 15.7037 10.6294 15.8239 11.4398Z"
                    stroke="#636e72"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
            </a>

            {/* Twitter */}

            <a href="https://twitter.com/abishuablessmic">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g id="Social/Twitter">
                  <path
                    id="Vector"
                    d="M20.9608 5.25489C21.1399 4.84457 20.6691 4.49899 20.2745 4.71049C19.6557 5.04213 19.0045 5.31177 18.3302 5.5148C15.6161 2.12518 10.94 4.97882 11.631 8.63441C11.6534 8.75303 11.5652 8.86786 11.4445 8.86559C8.90196 8.81779 7.10701 7.99065 5.37498 6.04184C5.12908 5.76516 4.69391 5.7782 4.50788 6.09821C3.36354 8.06663 0.538612 14.1724 7.80588 16.6205C6.38468 17.5852 4.53053 18.4045 3.58068 18.7963C3.34575 18.8932 3.33572 19.2266 3.56743 19.3309C13.0505 23.6026 22.2799 17.3808 19.3574 7.58866C20.0384 6.91712 20.5813 6.12419 20.9608 5.25489Z"
                    stroke="#636e72"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
            </a>

            {/* Youtube */}

            <a
              href="https://www.youtube.com/@abishuablessmic"
              className=" fill-[#303A47] "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 21 16"
                fill="none"
              >
                <g id="Component 1">
                  <path
                    id="Vector"
                    d="M1 8C1 5.54977 1 4.32466 1.51772 3.38879C1.55134 3.32802 1.58644 3.26809 1.62297 3.20903C2.08132 2.46813 2.76576 1.86533 3.59355 1.47685C4.60963 1 5.93975 1 8.6 1H12.4C15.0602 1 16.3904 1 17.4065 1.47685C18.2342 1.86533 18.9187 2.46813 19.377 3.20903C19.377 3.20903 19.4487 3.32802 19.4823 3.38879C20 4.32466 20 5.54977 20 8C20 10.4502 20 11.6753 19.4823 12.6112C19.0269 13.4344 18.3002 14.1037 17.4065 14.5232C16.3904 15 15.0602 15 12.4 15H8.6C5.93975 15 4.60963 15 3.59355 14.5232C2.69978 14.1037 1.97312 13.4344 1.51772 12.6112C1 11.6753 1 10.4502 1 8Z"
                    stroke="#636e72"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_2"
                    d="M8 12V4L14.5 8L8 12Z"
                    stroke="#636e72"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
            </a>

            {/* <a href="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="30"
                height="30"
                viewBox="0 0 30 30"
              >
                <path d="M8.5 7A8.5 8.5 0 108.5 24 8.5 8.5 0 108.5 7zM22 8A4 7.5 0 1022 23 4 7.5 0 1022 8zM28.5 9A1.5 6.5 0 1028.5 22 1.5 6.5 0 1028.5 9z"></path>
              </svg>
            </a> */}

            {/* linkedin */}

            <a
              href="https://www.linkedin.com/in/abishuablessmic/"
              className=" fill-[#535353] "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g id="Linkedin">
                  <path
                    id="Vector"
                    d="M8 11V16M11 10V12M11 16V12.2297C11 11.5147 11.5047 10.8991 12.2058 10.7588L13.6078 10.4784C14.8454 10.2309 16 11.1775 16 12.4396V16M8 8H8.01M3 12C3 9.48639 3 8.22958 3.35354 7.21924C3.98676 5.40959 5.40959 3.98676 7.21924 3.35354C8.22958 3 9.48639 3 12 3C14.5136 3 15.7704 3 16.7808 3.35354C18.5904 3.98676 20.0132 5.40959 20.6465 7.21924C21 8.22958 21 9.48639 21 12C21 14.5136 21 15.7704 20.6465 16.7808C20.0132 18.5904 18.5904 20.0132 16.7808 20.6465C15.7704 21 14.5136 21 12 21C9.48639 21 8.22958 21 7.21924 20.6465C5.40959 20.0132 3.98676 18.5904 3.35354 16.7808C3 15.7704 3 14.5136 3 12Z"
                    stroke="#636e72"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
            </a>

            {/* <a href="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="30"
                height="30"
                viewBox="0 0 64 64"
              >
                <path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26c14.359,0,26-11.641,26-26C58,17.641,46.359,6,32,6z M49.488,18.673 c2.739,3.586,4.399,8.035,4.5,12.862c-4.48-0.847-9.661-1.261-15.157-0.152c-0.656-1.523-1.345-3.041-2.086-4.547 C43.212,24.131,47.332,20.779,49.488,18.673z M46.766,15.706c-1.871,1.808-5.719,4.956-11.907,7.499 c-2.369-4.366-5.073-8.602-8.059-12.578C28.468,10.22,30.208,10,32,10C37.677,10,42.859,12.162,46.766,15.706z M22.824,12.013 c3.056,3.954,5.819,8.188,8.23,12.563c-5.393,1.703-12.17,2.839-20.502,2.548C12.079,20.4,16.694,14.839,22.824,12.013z M10,32 c0-0.3,0.011-0.599,0.023-0.896c1.112,0.046,2.208,0.076,3.271,0.076c7.911,0,14.417-1.22,19.684-2.948 c0.691,1.389,1.337,2.789,1.953,4.193c-6.267,2.099-12.812,6.412-19.101,14.473C12.214,42.976,10,37.743,10,32z M18.803,49.59 c5.859-7.593,11.865-11.596,17.655-13.475c2.097,5.409,3.593,10.831,4.384,16.024C38.134,53.332,35.144,54,32,54 C27.052,54,22.482,52.357,18.803,49.59z M44.564,50.044c-0.854-4.859-2.281-9.884-4.195-14.897c4.723-0.823,9.239-0.41,13.34,0.407 C52.733,41.535,49.338,46.71,44.564,50.044z"></path>
              </svg>
            </a> */}

            {/* product hunt */}

            {/* <a
              href="https://www.producthunt.com/@abishua"
              className=" fill-[#535353] "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="30"
                height="30"
                viewBox="0 0 30 30"
              >
                <path d="M 15 3 C 8.373 3 3 8.373 3 15 C 3 21.627 8.373 27 15 27 C 21.627 27 27 21.627 27 15 C 27 8.373 21.627 3 15 3 z M 11 8.9921875 L 16.5 8.9921875 C 18.985 8.9921875 21 11.007188 21 13.492188 C 21 15.977187 18.985 17.992187 16.5 17.992188 L 13.5 17.992188 L 13.5 21 L 11 21 L 11 9 L 11 8.9921875 z M 13.5 11.492188 L 13.5 15.492188 L 16.5 15.492188 C 17.603 15.492188 18.5 14.595188 18.5 13.492188 C 18.5 12.389188 17.603 11.492188 16.5 11.492188 L 13.5 11.492188 z"></path>
              </svg>
            </a> */}

            <a href="https://dribbble.com/abishuablessmic">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g id="Dribbble">
                  <path
                    id="Vector"
                    d="M6.26239 18.3C10.5374 13.0125 16.2749 11.6625 20.7749 12.9M3.22495 11.4375C9.97495 12 14.9249 8.7375 17.0624 4.6875M8.40006 3.9C11.5501 7.5 14.3626 12.675 15.7126 20.325M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                    stroke="#636e72"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
            </a>

            <a
              href="https://github.com/abishuablessmic"
              className=" fill-[#535353] "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g id="Github">
                  <path
                    id="Vector"
                    d="M10 14.9993C9.34732 15.6987 8.98919 16.6227 9 17.5793V20.9993M14 14.9993C14.6527 15.6987 15.0108 16.6227 15 17.5793V20.9993M9 19.0493C8.10549 19.4055 7.13532 19.5294 6.18 19.4093C4.66 18.8893 5.06 17.5093 4.28 16.9393C3.90518 16.6713 3.46037 16.5184 3 16.4993M19 9.74927C19 12.7493 17.05 14.9993 12 14.9993C6.95 14.9993 5 12.7493 5 9.74927C4.9753 8.70844 5.20893 7.67772 5.68 6.74927C5.34 5.27927 5.47 3.46927 6.2 3.10927C6.93 2.74927 8.47 3.40927 9.74 4.25927C10.486 4.12615 11.2422 4.05922 12 4.05927C12.7572 4.05262 13.5134 4.11285 14.26 4.23927C15.53 3.38927 17.14 2.75927 17.8 3.08927C18.46 3.41927 18.66 5.25927 18.32 6.72927C18.7943 7.66371 19.028 8.70171 19 9.74927Z"
                    stroke="#636e72"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>

      {/* Let's connect */}

      {/* <div className="flex justify-center ml-[10rem]
      bottom-60 fixed">
        <div  className="
         absolute
       
         
         h-[150px]
         w-[170px]"
         
         
         >
        <img
            src={avatar}
            fill
            style={{
              objectFit: "cover",
            }}
          />
        </div>
      </div>   */}

      {/* Image 02 */}

      <div
        className="flex 
      justify-center "
      >
        <div
          className="
          fixed
         
          h-[300px]
          w-[340px]
      bottom-[2rem]
      mr-[3rem]

          "
        >
          <img
            src={hi}
            fill
            style={{
              objectFit: "cover",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Sayhi;
