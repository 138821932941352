import React, { useState } from "react";
import { Link } from "react-router-dom";

import logo from "../assets/abLogo.svg";
import hamburger from "../assets/hamburger.svg";
import close from "../assets/x.svg";

const TopNav = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen((prev) => !prev);
  };



  
  return (
    <div className="">
      <div className="flex items-center justify-between lg:px-64 md:px-16 px-4 pt-6">
        <a href="/">
          <img src={logo} width={45} alt="Logo" style={{ opacity: isNavOpen ? 0 : 1, transition: "opacity 0.3s ease-in-out" }} />
        </a>
        <nav>
          <section className="MOBILE-MENU flex">
            <div className="HAMBURGER-ICON pb-4" onClick={handleNavToggle}>
              <span className="">
                <img src={hamburger} width={30} alt="Hamburger"  style={{ opacity: isNavOpen ? 0 : 1, transition: "opacity 0.3s ease-in-out" }} />
              </span>
            </div>

            {isNavOpen && (
              <div className="showMenuNav">
                <div
                  className="absolute top-0 right-0 lg:px-64 md:px-16 px-4 pt-6"
                  onClick={handleNavToggle}
                >
                  <img src={close} alt="Close"  />
                </div>

                <ul className="flex flex-col items-center justify-between" style={{ opacity: setIsNavOpen ? 1: 0, transition: "opacity 0.3s ease-in-out" }} >
                  <div className="pt-6 pr-5 flex gap-4 social-links" >
                    <a
                      href="https://dribbble.com/abishuablessmic"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="socialmedia-text"
                    >
                      🏀 dribb
                    </a>
                    <a
                      href="https://github.com/abishuablessmic"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="socialmedia-text"
                    >
                      👾 git
                    </a>
                    <a
                      href="https://medium.com/@abishuablessmic"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="socialmedia-text"
                    >
                      📚 medi
                    </a>
                    <a
                      href="https://www.snapchat.com/add/abishuablessmic"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="socialmedia-text"
                    >
                      👻snap
                    </a>
                  </div>
                </ul>
              </div>
            )}
          </section>
        </nav>
        <style>{`
          .hideMenuNav {
            display: none;
          }
          .showMenuNav {
            position: absolute;
            width: 100%;
            height: 80px;
            top: 0;
            background: #F7F2F2;
            left: 0;
            opacity: ${isNavOpen ? 1 : 0};
            transform: translateY(${isNavOpen ? "0" : "-100%"});
            transform: translateY(${isNavOpen ? "0" : "-100%"});
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;    
        
        
            opacity: ${setIsNavOpen ? 1:0};
            transform: translateY(${setIsNavOpen ? "0" : "-100%"});
            transform: translateY(${setIsNavOpen ? "0" : "-100%"});
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;    

             }
        `}</style>
      </div>
    </div>
  );
};

export default TopNav;
