
import { Analytics } from "@vercel/analytics/react"
import { Route, Routes } from "react-router-dom";
import Home from "../src/Routes/Home";
import { Whoami, Sayhi, Works, BottomNav, TopNav } from "./components";

import Crosswork from "../src/components/Projects/Crosswork"
import Sakthicaad from "../src/components/Projects/Sakthicaad"

const App = () => {
  return (
    <>
      <TopNav />
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sayhi" element={<Sayhi />} />
        <Route path="/works" element={<Works />} />
        <Route path="/works/cwc" element={<Crosswork />} />
        <Route path="/works/sakthicaad" element={<Sakthicaad />} />


      </Routes>
      <BottomNav />
    </>
  );
};

export default App;