import Whoami from "../components/Whoami";

const Home = () => {
  return (
    <div>
      <Whoami />
    </div>
  );
};


export default Home