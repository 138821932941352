import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import sound from "../assets/lick-sound.mp3";

const BottomNav = () => {
  const location = useLocation();

  const [show1, setShow1] = useState(location.pathname === "/");
  const [show2, setShow2] = useState(location.pathname === "/sayhi");
  const [show3, setShow3] = useState(location.pathname === "/works");

  const handleClick1 = () => {
    playClickSound();
    setShow1(true);
    setShow2(false);
    setShow3(false);
  };

  const handleClick2 = () => {
    playClickSound();
    setShow1(false);
    setShow2(true);
    setShow3(false);
  };

  const handleClick3 = () => {
    playClickSound();
    setShow1(false);
    setShow2(false);
    setShow3(true);
  };

  const playClickSound = () => {
    const clickSound = new Audio(sound);
    clickSound.play();
  };

  return (
    <div className="flex justify-center fixed inset-x-0 bottom-4">
      <div className="text-[#636e72] bg-[#F7F2F2]/70 backdrop-blur shadow-lg outline outline-[1.5px] outline-[#b2b2b2]/50 rounded-full px-1">
        {/* Whoami */}
        <div className="font-medium my-1">
          <Link to="/" onClick={handleClick1}>
            <button
              className={
                show1
                  ? "h-11 px-4 text-[#303A47] bg-[#ffff] rounded-full"
                  : "h-11 px-4"
              }
            >
              👀 Whoami
            </button>
          </Link>

          <Link to="/sayhi" onClick={handleClick2}>
            <button
              className={
                show2
                  ? "h-11 px-4 text-[#303A47] bg-[#fff] rounded-full"
                  : "h-11 px-4"
              }
            >
              👋🏼 Say Hi
            </button>
          </Link>

          <Link to="/works" onClick={handleClick3}>
            <button
              className={
                show3
                  ? "h-11 px-4 text-[#303A47] bg-[#ffff] rounded-full"
                  : "h-11 px-4"
              }
            >
              💼 Works
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BottomNav;
