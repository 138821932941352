import React from "react";
import hero  from "../../assets/sakthicaad.png"
import img01 from "../../assets/s01.png"
import img02 from "../../assets/s02.png"

import { ArrowUpRightIcon } from "@heroicons/react/24/solid";

function Sakthicaad() {
  return (
    <div className="m-4
    scroll-smooth">
      <div className=" flex justify-center ">
        <div
          className="
          relative 
        
          h-[284px]
          w-[390px]

          "
        >
          <img
            className="rounded-lg "
            src={hero}
            fill
            style={{
              objectFit: "cover",
            }}
          />
        </div>
      </div>

      <h2 className="font-bold text-2xl pt-7">Sakthicaad</h2>

      <hr />

      <div className="flex mt-3">
        <h3 className="font-medium text-xl">Category</h3>
        <p className="text-sm text-[#7A797D]  ml-[6.5rem]">
          Branding
          <br />
          UI/UX Design
        </p>
      </div>
      <div className="flex  mt-3">
        <p className="font-medium">Year</p>
        <p className="text-sm text-[#7A797D]  ml-[9.88rem]">2022</p>
      </div>

      <div className="flex  my-4">
        <p className="font-medium"> Client</p>
        <p className="text-sm text-[#7A797D]  ml-[9rem]">
          <a href="http://sakthicadd.com/">Sakthicaad</a>
        </p>
      </div>

      <div className="summary text-justify">
        <p>
        SAKTHICADD, is an industry leader in high quality PCB Design and
        Fabrication offering the very best service at competitive prices. With
        our resources and experience, we will be your complete Engineering
        partner no matter how large or small your project is. We can design
        package design for components, high-speed digital, analog and
        mixed-signal, and ultra-high-density boards in no time. With our
        experienced team, we can assure you to provide the astonishing designs
        that make your product stand out in the market..
        </p>

      </div>

      {/* models */}

      <div className="pb-9 pt-4">
        <span className="absolute">
          <ArrowUpRightIcon className="h-5 flex  w-6 text-[#f5f5f5]" />
        </span>
        <a
          href="http://sakthicadd.com/"
          className="no-underline hover:underline ... pl-6 text-[#f5f5f5]"
        >
          View the website
        </a>
      </div>


      <div className="relative flex justify-center mb-[6rem] ">

        <div className="">
        <div
          className="
          relative 
          flex-2
        
          h-[225px]
          w-[366px]

          "
        >
          <img
            className="rounded-lg "
            src={img01}
            fill
            style={{
              objectFit: "cover",
            }}
          />
        </div>


        <div
          className="
          relative 
        mt-5
       
        
          h-[225px]
          w-[366px]

          "
        >
          <img
            className="rounded-lg "
            src={img02}
            fill
            style={{
              objectFit: "cover",
            }}
          />
        </div>


        </div>
     


      </div>
    </div>
  );
}

export default Sakthicaad;
