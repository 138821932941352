import React from "react";
import logo from "../assets/abishua01.png";
// import cover from "../assets/cover.jpg";
// import resume from "../../src/assets/Abishua.pdf";
import toolkit from "../assets/toolkit.png";
import smilifyme from "../assets/smilifyme.png";
import zippy from "../assets/zippy.png";

//Bento grids assets

import twitter from "../assets/twitterlogo.svg";
import tastelio from "../assets/tastelioImg.png";
import insta from "../assets/insta.png";
import linkedin from "../assets/linkedin.svg";

import tastie from "../assets/tastie.png";

import dsa from "../assets/guideme- DSA.svg";

import Tech from "./Tech";

const Whoami = () => {
  return (
    <div>
      <div className="px-72 max-sm:px-3  pt-3">
        <div className="bg-white rounded-2xl ">
          <div className="container relative pb-4  ">
            <div className="pt-4">
              <div className="profile flex ml-4 mr-3 ">
                {/* profile */}

                <div className="">
                  <div className="relative h-[100px] w-[100px]">
                    <img src={logo} fill style={{ objectFit: "cover" }} />
                  </div>
                  <span className="w-7 h-7 border-2 bg-white border-white rounded-full text-center right-0 left-0 absolute -translate-y-10 translate-x-[5.8rem] lg:translate-x-[6rem] lg:-translate-y-10 shadow-md">
                    🏗️
                  </span>
                </div>

                {/* bio */}
                <div className="bio pt-3 pl-5 text-sm">
                  <p className="font-semibold text-base text-[#1d1d1d]">
                    Hola <span class="animate-waving-hand">👋</span> I’m Abishua
                    Blessmic
                  </p>
                  <p className="text-sm font-medium text-justify text-[#636e72] ">
                    23. Designer, Developer
                    <br />
                    📍Tuticorin, TN
                  </p>
                </div>
              </div>
            </div>

            {/* *** about & experience ***  */}

            {/* *** tools & skills ***  */}
          </div>

          <div className=" px-3 ">
            <div className="flex justify-center ">
              <div className="about">
                <span>
                  {/* <h1 className="font-semibold text-xl mb-2">About me 👀</h1> */}
                </span>
                <h1 className="font-medium text-[15px]  text-[#636e72]  mb-2">
                  Turning <span>Ideas💡into Products🚀</span> is my calling. I
                  bring a fresh perspective to every project. Like
                  <span> Designing🎨</span> stunning interfaces,
                  <span> Building🏗️</span> immersive AR experiences and
                  <span> Crafting🧵</span> intuitive user experiences.
                  {/* You can find me on <span><a href="https://twitter.com/abishuablessmic">Twitter</a></span> where I talk about tech, design, fun stuff and anything that catches my attention, or on <span><a href="https://github.com/abishuablessmic">GitHub</a></span> where I build things in the open, or on <span><a href="https://www.instagram.com/storiesofabi/">Instagram</a></span>. */}
                  <br />
                  <a className="pt-1 text-xs" href="https://read.cv/abishua">
                    {" "}
                    🔗 Click here *{" "}
                  </a>
                  <br />
                </h1>
              </div>
            </div>

            {/* <div className="pt-2 flex justify-center">
        <Tweet tweetId="1637848345866833921" />
      </div>

      <div className="flex justify-center pt-7">
        <span>
          <h1 className="font-semibold text-xl mb-2">Recent📌</h1>
        </span>

        <div className="bg-[#fcfcfc] shadow-md rounded-xl flex">
          <a
            href="https://medium.com/@abishuablessmic/how-to-improve-as-a-designer-1b7583abe254
            "
          >
            <img
              className="relative h-[120px] w-[220px] py-2 pl-2 rounded-[20px]"
              src={cover}
              alt="Blog Cover"
            />
          </a>

          <div className="flex flex-col justify-center">
            <p className=" pl-2 font-semibold text-[#535353]">
              How to improve as a designer?
            </p>
            <p className="pl-2 pr-3  font-[450] text-[#535353]">
              Apart from the obvious answer Practice, there are other ways{" "}
              <span className=" text-[#535353]">
                <a
                  className="text-sm  text-[#535353]"
                  href="https://medium.com/@abishuablessmic/how-to-improve-as-a-designer-1b7583abe254"
                >
                  Read more . .
                </a>
              </span>
            </p>
          </div>
        </div>
      </div> */}
          </div>
        </div>
      </div>

      <div className="  ">
        {/* Bento Grids */}

        <div className="p-3  ">
          <div className="flex max-sm:flex-wrap justify-center  ">
            <div className="grid grid-cols-2 md:grid-cols-2  gap-3">
              <div className="grid gap-4">
                <div className="relative">
                  <div className="absolute bottom-0 pb-3 px-3 cursor-pointer">
                    <a href="https://twitter.com/abishuablessmic">
                      <div className=" p-2  rounded-full bg-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#000"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            fill=""
                            d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                          />
                        </svg>
                      </div>
                    </a>
                  </div>

                  <div className=" bg-black w-full  h-[160px]  lg:w-52  items-center flex justify-center rounded-3xl">
                    <img
                      src={twitter}
                      alt="Twitter"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </div>

                <div className="relative">
                  <div className="absolute bottom-0 pb-3 px-3 cursor-pointer left-1/2 transform -translate-x-1/2  ">
                    <a href="https://www.instagram.com/storiesofabi/">
                      <div className="rounded-full linkbtn backdrop-blur-sm bg-black/30  px-5 py-1 ">
                        <div className="flex items-center gap-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g id="Social/Instagram">
                              <path
                                id="Vector"
                                d="M17.0464 6.99945H17.0564M3.04639 12C3.04639 9.48639 3.04639 8.22958 3.39993 7.21924C4.03315 5.40959 5.45598 3.98676 7.26563 3.35354C8.27597 3 9.53278 3 12.0464 3C14.56 3 15.8168 3 16.8272 3.35354C18.6368 3.98676 20.0596 5.40959 20.6929 7.21924C21.0464 8.22958 21.0464 9.48639 21.0464 12C21.0464 14.5136 21.0464 15.7704 20.6929 16.7808C20.0596 18.5904 18.6368 20.0132 16.8272 20.6465C15.8168 21 14.56 21 12.0464 21C9.53278 21 8.27597 21 7.26563 20.6465C5.45598 20.0132 4.03315 18.5904 3.39993 16.7808C3.04639 15.7704 3.04639 14.5136 3.04639 12ZM15.8239 11.4398C15.9417 12.2344 15.806 13.0458 15.436 13.7588C15.0661 14.4718 14.4808 15.0499 13.7633 15.4111C13.0458 15.7722 12.2327 15.8979 11.4397 15.7703C10.6467 15.6427 9.91407 15.2683 9.3461 14.7003C8.77813 14.1323 8.40371 13.3997 8.27611 12.6067C8.1485 11.8137 8.2742 11.0006 8.63532 10.2831C8.99644 9.56564 9.57461 8.98031 10.2876 8.61036C11.0006 8.24042 11.812 8.1047 12.6065 8.22253C13.417 8.3427 14.1673 8.72036 14.7467 9.29971C15.326 9.87907 15.7037 10.6294 15.8239 11.4398Z"
                                stroke="#f5f5f5"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                          </svg>

                          <p className=" text-white">@storiesofabi</p>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="lg:w-52  ">
                    <img
                      src={insta}
                      alt="instagram"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </div>

                {/* Add more grid items as needed */}
              </div>
              <div className="grid gap-3">
                <div className="relative">
                  <div className="absolute bottom-0 pb-3 px-3 cursor-pointer">
                    <a href="https://dribbble.com/shots/22192484-Recipe-Landing-Page-UI?utm_source=Clipboard_Shot&utm_campaign=abishuablessmic&utm_content=Recipe%20Landing%20Page%20UI&utm_medium=Social_Share&utm_source=Clipboard_Shot&utm_campaign=abishuablessmic&utm_content=Recipe%20Landing%20Page%20UI&utm_medium=Social_Share">
                      <div className=" p-2  rounded-full bg-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#000"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            fill=""
                            d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                          />
                        </svg>
                      </div>
                    </a>
                  </div>

                  <div className="lg:w-52  ">
                    <img
                      src={tastelio}
                      alt="Tastelio project"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </div>

                <div className="relative">
                  <div className="absolute bottom-0 pb-3 px-3 cursor-pointer">
                    <a href="https://www.linkedin.com/in/abishuablessmic/">
                      <div className=" p-2  rounded-full bg-[#F4F4F4]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#000"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            fill=""
                            d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                          />
                        </svg>
                      </div>
                    </a>
                  </div>

                  <div className="bg-white w-full h-[160px] lg:w-52   items-center flex justify-center rounded-3xl">
                    <img
                      src={linkedin}
                      alt="Twitter"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </div>

                {/* Add more grid items as needed */}
              </div>
            </div>

            <div>
              <div className="max-sm:pt-3 lg:w-[23rem] lg:pl-3   ">
                <div className="bg-white w-full p-3 rounded-2xl">
                  <div>
                    <h1 className="font-semibold text-lg">
                      How to improve as a Designer
                    </h1>
                    <p className="font-medium text-sm">
                      Apart from the obvious answer #Practice , there are other
                      ways to become a better designer.
                    </p>
                  </div>

                  <div className="flex pt-5 justify-between items-center">
                    <button className="text-xs border-2 border-[#F4F4F4] px-3 py-2 rounded-full font-medium">
                      Read more
                    </button>
                    <p className="text-xs font-medium text-[#747474]">
                      Feb12,2021
                    </p>
                  </div>
                </div>

                <div>
                  {" "}
                  <div className="pt-4 lg:">
                    <img
                      src={tastie}
                      alt="Twitter"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center px-6"></div>
      <Tech />

      {/* <div className="flex justify-center">
<img
                      src={dsa}
                      alt="Twitter"
                      style={{ objectFit: "cover" }}
                    />
</div> */}
    </div>
  );
};

export default Whoami;
