import React from 'react'
import figma from "../assets/tools/figma.png"
import blender from "../assets/tools/blender.png"
import notion from "../assets/tools/notion.png"
import spotify from "../assets/tools/spotify.png"
import discord from "../assets/tools/discord.png"
import slack from "../assets/tools/slack.png"
import vscode from "../assets/tools/vscode.png"
import ubuntu from "../assets/tools/ubuntu.png"
import terminal from "../assets/tools/terminal.png"
import chrome from "../assets/tools/chrome.png"
import postman from "../assets/tools/postman.png"
import nextjs from "../assets/tools/nextjs.png"
import gumroad from "../assets/tools/gumroad.png"
import lenstudio from "../assets/tools/lenstudio.png"
import metaspark from "../assets/tools/sparkar.png"
import unity from "../assets/tools/unity.png"
import photoshop from "../assets/tools/photoshop.png"
import illustrator from "../assets/tools/Illustrator.png"
import davinci from "../assets/tools/da-vinci.png"
import obs from "../assets/tools/obs.png"



// devices



import mac from "../assets/tools/macbookpro.png"
import tab from "../assets/tools/samsung.png"
import rog from "../assets/tools/asusrog.png"
import wacom from "../assets/tools/wacom.png"





const Tech = () => {
  return (


    <div className=' pt-7 pb-20'>

        <h1 className='text-2xl font-semibold text-[#1d1d1d] text-center'>Tech Stack</h1>
        <p className='text-center  font-medium text-[#636e72]'>My secret recipe for designing, coding, managing, entertaining and all...</p>

        <p className='text-center pt-3 font-medium text-xl text-[#1d1d1d]/70'>Apps</p>
<div className='flex justify-center pt-7 px-3'>

    


      <div className='lg:px-64'>
      <div className='flex flex-wrap w-full justify-center gap-6 p-3 py-4 bg-white rounded-2xl '>

<a href="">
    
<img 

className="w-12 h-12 "
src={figma}
alt="Figma" /> 

</a>


<a href="">
    
<img 

className="w-12 h-12 "
src={blender}
alt="Figma" /> 

</a>

<a href="">
    
    <img 
    
    className="w-12 h-12 "
    src={notion}
    alt="Figma" /> 

    </a>


    <a href="">
        
    <img 
    
    className="w-12 h-12 "
    src={spotify}
    alt="Figma" /> 

    </a>




    <a href="">
    
    <img 
    
    className="w-12 h-12 "
    src={discord}
    alt="Figma" /> 

    </a>


    <a href="">
        
    <img 
    
    className="w-12 h-12 "
    src={slack}
    alt="Figma" /> 

    </a>

    <a href="">
        
        <img 
        
        className="w-12 h-12 "
        src={vscode}
        alt="Figma" /> 

        </a>


        <a href="">
            
        <img 
        
        className="w-12 h-12 "
        src={ubuntu}
        alt="Figma" /> 

        </a>
    
















        <a href="">
    
    <img 
    
    className="w-12 h-12 "
    src={terminal}
    alt="Figma" /> 

    </a>


    <a href="">
        
    <img 
    
    className="w-12 h-12 "
    src={chrome}
    alt="Figma" /> 

    </a>

    <a href="">
        
        <img 
        
        className="w-12 h-12 "
        src={postman}
        alt="Figma" /> 

        </a>


        <a href="">
            
        <img 
        
        className="w-12 h-12 "
        src={nextjs}
        alt="Figma" /> 

        </a>




        <a href="">
        
        <img 
        
        className="w-12 h-12 "
        src={gumroad}
        alt="Figma" /> 

        </a>


        <a href="">
            
        <img 
        
        className="w-12 h-12 "
        src={lenstudio}
        alt="Figma" /> 

        </a>

        <a href="">
            
            <img 
            
            className="w-12 h-12 "
            src={metaspark}
            alt="Figma" /> 
    
            </a>
    
    
            <a href="">
                
            <img 
            
            className="w-12 h-12 "
            src={unity}
            alt="Figma" /> 
    
            </a>


            <a href="">
        
        <img 
        
        className="w-12 h-12 "
        src={photoshop}
        alt="Figma" /> 

        </a>


        <a href="">
            
        <img 
        
        className="w-12 h-12 "
        src={illustrator}
        alt="Figma" /> 

        </a>

        <a href="">
            
            <img 
            
            className="w-12 h-12 "
            src={davinci}
            alt="Figma" /> 
    
            </a>
    
    
            <a href="">
                
            <img 
            
            className="w-12 h-12 "
            src={obs}
            alt="Figma" /> 
    
            </a>


</div>

      </div>
        




</div>

<p className='text-center pt-10 font-medium text-xl text-[#1d1d1d]/70'>Devices</p>



<div className='flex justify-center pt-7  lg:px-52 px-3'>
<div className='flex flex-wrap  gap-6 px-4 max-sm:w-full   justify-center  p-3 py-4 bg-white rounded-2xl'>

<a href="">
    
<img 

className="w-12 h-12  "
src={mac}
alt="Figma" /> 

</a>
<a href="">
    
<img 

className="w-12 h-12 "
src={rog}
alt="Figma" /> 

</a>


<a href="">
    
<img 

className="w-12 h-12 "
src={tab}
alt="Figma" /> 

</a>


<a href="">
    
<img 

className="w-12 h-12 "
src={wacom}
alt="Figma" /> 

</a>






         


</div>
</div>



    </div>

    
  )
}

export default Tech