import React from "react";
import hero from "../../assets/cwc.png"

function Crosswork() {
  return (
    <div className="m-4 pb-28">
      <div className=" flex justify-center ">
        <div
          className="
          relative 
          h-[230px]
          w-[360px]

        
          "
        >
          <img
            className="rounded-lg "
            src={hero}
            fill
            style={{
              objectFit: "cover",
            }}
          />
        </div>
      </div>

      <h2 className="font-bold text-2xl pt-7 "> Crosswork Creation</h2>

      <hr />

      <div className="flex mt-3">
        <h3 className="font-medium text-xl">Category</h3>
        <p className="text-sm text-[#7A797D]  ml-[6.5rem]">
          Branding
          <br />
          Logo Design
        </p>
      </div>
      <div className="flex  mt-3">
        <p className="font-medium">Year</p>
        <p className="text-sm text-[#7A797D]  ml-[9.88rem]">2021</p>
      </div>

      <div className="flex  my-4">
        <p className="font-medium"> Client</p>
        <p className="text-sm text-[#7A797D]  ml-[9rem]">
          <a href="">Jackson Daniel</a>
        </p>
      </div>

      <div className="summary text-justify"><p>

      Crosswork Creations is a Christian ministry that is dedicated to
        empowering young people to grow in Christ. One of the key challenges
        that #CWC faced was finding ways to engage young people in meaning full
        way.They were able to use social media in a powerful and effective way
        to spread the word of GOD and make a real difference in the lives of
        young people.
      </p>
    
      </div>

      <div className="mt-4">
        <h1 className=" flex justify-center font-semibold text-xl">
          Cross + Work + Creation
        </h1>
      </div>

   
    </div>
  );
}

export default Crosswork;
